<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading">Probation Review Form</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-form ref="form" @submit.prevent="submitForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>
                  Rating: Very Good (1) &nbsp;&nbsp; Good (2) &nbsp;&nbsp;
                  Average (3) &nbsp;&nbsp; Below Average (4) &nbsp;&nbsp; Poor
                  (5)
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <span>
                  <!-- <a
            target="_blank"
            :href="ipURL + '/v1/admin/user/download-pdf?id=' + userData._id"
            class="downlink"
          > -->
                  <span
                    ><v-icon color="red">mdi-file-pdf-box</v-icon>
                    Download</span
                  >
                  <!-- </a> -->
                </span>
              </v-col>
            </v-row>
            <v-row v-for="(competency, index) in competencies" :key="index">
              <v-col cols="12" class="text-left">
                <span>{{ index + 1 }}.&nbsp;{{ competency.competency }}</span>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="competency.rating"
                  :items="ratings"
                  label="-select-"
                  disabled
                  outlined
                  :rules="[(v) => !!v || 'Rating is required']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="comments"
                  disabled
                  label="Overall comments/ Remarks"
                  outlined
                  :rules="[(v) => !!v || 'Remarks are required']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-left">
                <p>Recommendation (Please Tick One):</p>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="isCleared"
                  label="Recommended for clearing of probation period"
                  @change="validateCheckbox('isCleared')"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="isExtension"
                  label="Recommended for extension of the probation for"
                  @change="validateCheckbox('isExtension')"
                  disabled
                ></v-checkbox>
                <v-select
                  v-if="isExtension"
                  v-model="extensionMonths"
                  :items="months"
                  label="Number of months"
                  disabled
                  outlined
                ></v-select>
                <v-checkbox
                  v-model="notCleared"
                  label="Not recommended for clearance / extension of probation"
                  @change="validateCheckbox('notCleared')"
                  disabled
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              wrap
              justify="center"
              v-if="this.data && this.data.isApproved == false"
            >
              <v-col cols="12" sm="5" class="text-center">
                <v-btn
                  color="#005f32"
                  dark
                  tile
                  large
                  class="rounded-xl"
                  block
                  @click="openConfirmDialog()"
                >
                  Approve
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="confirmDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            align-self-center
            pa-4
            style="background: #005f32"
            text-left
          >
            <span class="kumbhBold" style="color: #ffffff"
              >Approve Confirmation</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap pa-6>
          <v-flex xs12 align-self-end pa-3>
            <span class="kumbhMedium"
              >Are you sure you want to approve this probation review?</span
            >
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" tile dark @click="confirmDialog = false"
            >Cancel</v-btn
          >
          &nbsp;
          <v-btn color="#005f32" outlined tile @click="confirmApproval()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      data: {},
      rating: null,
      ratings: [1, 2, 3, 4, 5],
      comments: "",
      isCleared: false,
      isExtension: false,
      extensionMonths: null,
      confirmDialog: false,
      notCleared: false,
      months: Array.from({ length: 12 }, (_, i) => i + 1),
      competencies: [
        { competency: "Understanding of the organization", rating: null },
        { competency: "Understanding of the project", rating: null },
        {
          competency: "Technical knowledge with regard to the work",
          rating: null,
        },
        { competency: "Project Management", rating: null },
        { competency: "Time Management", rating: null },
        { competency: "Eagerness to learn", rating: null },
        { competency: "Team working skills", rating: null },
        { competency: "Communication ability", rating: null },
        { competency: "Professionalism", rating: null },
        { competency: "Crisis Management", rating: null },
        { competency: "Level of maturity", rating: null },
      ],
    };
  },

  beforeMount() {
    this.getData();
  },
  methods: {
    openConfirmDialog() {
      this.confirmDialog = true;
    },
    confirmApproval() {
      this.confirmDialog = false;
      this.ApproveForm(); // Call the existing submitForm method
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/probationreviewform/view",
        method: "POST",
        data: {
          employeeId: this.$route.query.employeeId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.data = response.data.data;
            this.competencies[0].rating = this.data.Understandorganization;
            this.competencies[1].rating = this.data.Understandproject;
            this.competencies[2].rating = this.data.TechnicalknowledgeWork;
            this.competencies[3].rating = this.data.ProjectManagement;
            this.competencies[4].rating = this.data.TimeManagement;
            this.competencies[5].rating = this.data.Eagernesstolearn;
            this.competencies[6].rating = this.data.Teamworkingskills;
            this.competencies[7].rating = this.data.CommunicationAbility;
            this.competencies[8].rating = this.data.Professionalism;
            this.competencies[9].rating = this.data.CrisisManagement;
            this.competencies[10].rating = this.data.LevelMaturity;
            this.comments = this.data.Remarks;
            this.isCleared = this.data.isCleared;
            this.isExtension = this.data.isExtension;
            this.extensionMonths = this.data.ExtensionNoOfMonths;
            this.notCleared = this.data.notCleared;
          } else {
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateCheckbox(changedCheckbox) {
      if (changedCheckbox === "isCleared" && this.isCleared) {
        this.isExtension = false;
        this.notCleared = false;
        this.extensionMonths = null;
      } else if (changedCheckbox === "isExtension" && this.isExtension) {
        this.isCleared = false;
        this.notCleared = false;
      } else if (changedCheckbox === "notCleared" && this.notCleared) {
        this.isCleared = false;
        this.isExtension = false;
        this.extensionMonths = null;
      }
    },
    ApproveForm() {
      this.appLoading = true;
      axios({
        url: "/probationreviewform/approve",
        method: "POST",
        data: {
          employeeId: this.$route.query.employeeId,
          id: this.data._id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>